import React from "react"

const StepCard = ({
  step,
  title,
  children,
  image,
  _classes,
  color = "light",
  reverse = false,
}) => {
  const cardStyles = `step-card d-flex step-card--${color}${
    _classes ? " " + _classes : ""
  } ${reverse ? "flex-wrap" : "flex-reverse-wrap"}`

  if (reverse)
    return (
      <div className={cardStyles} data-aos="fade-right">
        <div className="step-card__content column justify-content-center col-12 col-lg-7">
          <p className="step-card__step">{step}</p>
          <h3 className="step-card__title">{title}</h3>
          {children}
        </div>
        <div className="step-card__image col-12 col-lg-5" style={{backgroundImage: `url(${image})`}}>
        </div>
      </div>
    )

  return (
    <div className={cardStyles} data-aos="fade-left">
      <div className="step-card__image col-12 col-lg-5" style={{backgroundImage: `url(${image})`}}>
      </div>
      <div className="step-card__content column justify-content-center col-12 col-lg-7">
        <p className="step-card__step">{step}</p>
        <h3 className="step-card__title">{title}</h3>
        {children}
      </div>
    </div>
  )
}

export default StepCard
