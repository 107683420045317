import * as React from "react"

import { StaticImage } from "gatsby-plugin-image"

import Header from "../components/Header"
import Section from "../components/Section"
import Seo from "../components/seo"
import Card from "../components/Card"
import StepCard from "../components/StepCard"
import Carousel from "../components/Carousel"
import StyledSection from "../components/StyledSection"

import lecture from "../images/lecture.jpg"
import area from "../images/maneuvering-area.jpg"
import exam from "../images/driving-school.jpg"
import city from "../images/jam.jpg"

import {
  warning,
  forbiden,
  warrant,
  informationsSigns,
  directions,
  additional,
  crossing,
  bike,
  horizontal,
  lights,
  tables,
} from "../signs"
import { Link } from "gatsby"

const signs = [
  {
    id: "p01",
    title: "Znaki drogowe",
    subtitle: "ostrzegawcze",
    postscript: "Ilość",
    postScriptAmount: warning.length,
    context: warning,
  },
  {
    id: "p02",
    title: "Znaki drogowe",
    subtitle: "zakazu",
    postscript: "Ilość",
    postScriptAmount: forbiden.length,
    context: forbiden,
  },
  {
    id: "p03",
    title: "Znaki drogowe",
    subtitle: "nakazu",
    postscript: "Ilość",
    postScriptAmount: warrant.length,
    context: warrant,
  },
  {
    id: "p04",
    title: "Znaki drogowe",
    subtitle: "informacyjne",
    postscript: "Ilość",
    postScriptAmount: informationsSigns.length,
    context: informationsSigns,
  },
  {
    id: "p05",
    title: "Znaki drogowe",
    subtitle: "kierunku i miejscowości",
    postscript: "Ilość",
    postScriptAmount: directions.length,
    context: directions,
  },
  {
    id: "p06",
    title: "Znaki drogowe",
    subtitle: "uzupełniające",
    postscript: "Ilość",
    postScriptAmount: additional.length,
    context: additional,
  },
  {
    id: "p07",
    title: "Znaki drogowe",
    subtitle: "przejazdów kolejowych",
    postscript: "Ilość",
    postScriptAmount: crossing.length,
    context: crossing,
  },
  {
    id: "p08",
    title: "Znaki drogowe",
    subtitle: "poziome",
    postscript: "Ilość",
    postScriptAmount: horizontal.length,
    context: horizontal,
  },
  {
    id: "p09",
    title: "Znaki drogowe",
    subtitle: "szlaków rowerowych",
    postscript: "Ilość",
    postScriptAmount: bike.length,
    context: bike,
  },
  {
    id: "p10",
    title: "Znaki drogowe",
    subtitle: "świetlne",
    postscript: "Ilość",
    postScriptAmount: lights.length,
    context: lights,
  },
  {
    id: "p11",
    title: "Znaki drogowe",
    subtitle: "tabliczki",
    postscript: "Ilość",
    postScriptAmount: tables.length,
    context: tables,
  },
]

const stepCards = [
  {
    title: "Kursy teoretyczne",
    color: "dark",
    image: lecture,
    context: (
      <p className="step-card__text">
        Solidne opanowanie zasad ruchu drogowego to podstawa, bez tego nie
        będziesz w stanie rozpocząć praktycznej nauki. Przepisy ruchu drogowego
        musi znać każdy kierowca, warto tę wiedzę co pewien czas sobie
        odświeżyć, gdyż przepisy mogą ulec zmianie, a każdy kierowca powinien
        być na bieżąco ze zmianami.
        <br />
        Nasza szkoła jazdy oferuje naukę zdalną, jeżeli chcesz dowiedzieć się
        więcej o możliwości takiej nauki,{" "}
        <Link to="/kontakt">skontaktuj się z nami</Link>.
      </p>
    ),
  },
  {
    title: "Jazdy po mieście",
    color: "light",
    image: city,
    reverse: true,
    context: (
      <p className="step-card__text">
        Plac manewrowy zaliczony, podstawowe manewry opanowane, więc wyruszamy
        na miasto. Na tym etapie musisz potrafić wykorzystać wiedzę teoretyczną
        w praktyce, dlatego tak istotnym krokiem jest opanowanie teorii.
      </p>
    ),
  },
  {
    title: "Plac manewrowy",
    color: "dark",
    image: area,
    context: (
      <p className="step-card__text">
        Po solidnym opanowaniu teorii, będziesz mógł/mogła rozpocząć pierwsze
        jazdy na placu manewrowym. Na tym etapie nauczysz się obsługi samochodu,
        prezentacji pojazdu oraz rozpoczniesz naukę podstawowych manewrów.
      </p>
    ),
  },
  {
    title: "Egzamin wewnętrzny",
    color: "light",
    image: exam,
    reverse: true,
    context: (
      <p className="step-card__text">
        Po przejechaniu przynajmniej 30 godzin i nabraniu pewności za
        kierwonicą, będziesz mógł/mogła przystąpić do egzaminu państwowego.
        Jednak przed egzaminem państwowym otrzymasz możliwość sprawdzenia się
        podczas egzaminu wewnętrzengo zarówno praktycznego i teoretycznego,
        które będą przebiegały identycznie jak egzaminy w WORD-zie.
      </p>
    ),
  },
]

const movies = [
  {
    id: "mv1",
    title: "Wjazd/wyjazd z WORD-u Tychy",
    link: "https://www.youtube.com/watch?v=W-Kq0Lqg5ZM",
  },
  {
    id: "mv2",
    title: "Światła Toyota Yaris",
    link: "https://www.youtube.com/watch?v=Jt1lQ6x3VuE",
  },
  {
    id: "mv3",
    title: "Jak ustawić lusterka? Toyota Yaris",
    link: "https://www.youtube.com/watch?v=yTU5tYdsX3k",
  },
  {
    id: "mv4",
    title: "Pod pokrywą silnika",
    link: "https://www.youtube.com/watch?v=DH4BD3iDv2g",
  },
]

const TrainingPage = () => {
  const steps = stepCards.map((item, index) => (
    <StepCard
      key={index}
      step={`krok ${index + 1}`}
      title={item.title}
      color={item.color}
      image={item.image}
      reverse={item.reverse}
    >
      {item.context}
    </StepCard>
  ))

  return (
    <>
      <Seo
        title="Szkolenia"
        description={
          "Zależy nam na tym, aby kurs prawa jazdy przygotował Cię w sposób kompleksowy do egzaminu w WORD oraz do praktycznej i bezpiecznej nauki jazdy każdego dnia już po zdanym egzaminie."
        }
      />
      <Header
        title={
          <>
            <span className="d-block header__title--primary">
              Nauka z OSK Adept
            </span>
            Jak przebiega kurs
          </>
        }
      >
        <StaticImage
          className="header__hero"
          src="../images/training.jpg"
          alt="szkoła jazdy"
        />
      </Header>
      <Section title="Jak przebiega szkolenie?">{steps}</Section>
      <StyledSection>
        <h2 className="styled-section__title">Filmiki instruktażowe</h2>
        <p className="styled-section__text">
          Dla wszystkich początkujących kierwców nasza szkoła jazdy przygotowała
          filmiki instruktarowe. Wszystkie filmiki dostępne są za darmo na
          naszym kanale{" "}
          <a
            href="https://www.youtube.com/channel/UC-CiV5h4s17aprb-7-CBzKg/featured"
            target="_blank"
            rel="noreferrer"
          >
            YouTube
          </a>
          . Możecie na nim znaleźć:
        </p>

        <ul className="styled-section__list">
          {movies.map(({ id, link, title }) => (
            <li key={id}>
              <a href={link} target="_blank" rel="noreferrer">
                {title}
              </a>
            </li>
          ))}
        </ul>
      </StyledSection>
      <Section title="Naucz się znaków" _id="znaki">
        <Carousel>
          {signs.map(item => (
            <Card key={item.id} {...item} />
          ))}
        </Carousel>
      </Section>
    </>
  )
}

export default TrainingPage
