import React, { useState, useRef, useEffect } from "react"

import CtaBtn from "./CtaBtn"

const Carousel = ({ children }) => {
  const cRef = useRef(null)
  const [x, setX] = useState(0)
  const gap = 40

  const previous = () => {
    const cardWidth = cRef.current.firstChild.offsetWidth
    const size = gap + cardWidth
    if (x + size >= 0) return setX(cardWidth / 2)
    setX(x + size)
  }

  const next = () => {
    const cardWidth = cRef.current.firstChild.offsetWidth
    const size = gap + cardWidth
    const maxScroll = -cRef.current.scrollWidth + size

    if (x - size <= maxScroll) return setX(maxScroll)

    setX(x - size)
  }

  useEffect(() => {
    setX(cRef.current.firstChild.offsetWidth / 2)
  }, [cRef])

  return (
    <div className="carousel">
      <CtaBtn _classes="carousel__btn" action={previous} _label="cofnij" />
       <div className="carousel__container">
      <div
        className="carousel__list d-flex"
        ref={cRef}
        style={{ transform: `translateX(${x}px)`, gap: `${gap}px` }}
      >
        {children}
      </div>
    </div>
      <CtaBtn _classes="carousel__btn" action={next} _label="dalej" />
    </div>
  )
}

export default Carousel
