import React from "react"

import Container from "./Container"

const Section = ({ children, title, _id }) => {
  return (
    <section className="section" id={_id}>
      <Container>
        <h2 className="section__title">{title}</h2>
        {children}
      </Container>
    </section>
  )
}

export default Section
