import React from "react"

const StyledSection = ({children}) => {
  return (
    <section className="styled-section" >
      <div className="styled-section__container column justify-content-center ml-auto" data-aos='fade-left'>
        <div className="styled-section__content">
          {children}
        </div>
      </div>
    </section>
  )
}

export default StyledSection
