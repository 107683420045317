import React, { useContext } from "react"

import Arrow from "../images/icons/arrow-light.svg"

import { ModalContext } from "./layout"

const Card = ({ title, subtitle, postscript, postScriptAmount, postScriptOldAmount, context }) => {
  const { openModal, setUpModalContent } = useContext(ModalContext)

  const onCardClick = () => {
    setUpModalContent({
      title,
      subtitle,
      context,
      postscript,
      postScriptAmount,
      postScriptOldAmount,
    })
    openModal()
  }

  return (
    <div
      className="card"
      onClick={onCardClick}
      onKeyPress={e => {
        if (e.code !== 13) return
        return onCardClick()
      }}
      role="button"
      tabIndex={0}
    >
      <div className="card__container column justify-content-between">
        <div>
          <h3 className="card__title">{title}</h3>
          <h4 className="card__subtitle">{subtitle}</h4>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <p className="card__postscript">
            <span className="card__postscript--bold">{postscript}: </span>
            {postScriptAmount}
            <del className="card__postscript-old">{postScriptOldAmount}</del>
          </p>
          <p className="card__action d-flex align-items-center">Więcej <Arrow /></p>
        </div>
      </div>
    </div>
  )
}

export default Card
