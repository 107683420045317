import React from 'react'

import Container from "../components/Container"


const Header = ({children, title}) => {
  return (
    <header className="header d-flex align-items-end">
    <Container>
      <h1 className="header__title">
        {title}
      </h1>
    </Container>
        {children}

  </header>
  )
}

export default Header
